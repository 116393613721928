import { render, staticRenderFns } from "./ElevatorEdit.vue?vue&type=template&id=71a58aba&scoped=true"
import script from "./ElevatorEdit.vue?vue&type=script&lang=js"
export * from "./ElevatorEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a58aba",
  null
  
)

export default component.exports